import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import styled from '@emotion/styled'
import tw from 'tailwind.macro'
import { Helmet } from 'react-helmet'
import { SocialIcon } from 'react-social-icons'

import BgImage from '../components/BgImage'

// ========================
// The primary card container
const Full = styled.div`
  ${tw`
    flex items-center justify-center h-screen w-full bg-teal-100
  `} /* max-width: 500px; */;
`

const Card = styled.div`
  ${tw`
    z-10
    bg-white rounded-lg shadow-xl p-12 w-4/5 h-auto m-4 text-center
  `} /* max-width: 500px; */;
`
// ========================

// ========================
// The card header
const CardHeader = styled.h1`
  ${tw`text-4xl mb-4 uppercase  font-bold leading-tight text-red-800`};
`
// ========================

// ========================
// Primary copy (inc. links, etc)
const CardCopy = styled.div`
  p {
    ${tw`
      text-lg mb-2 sm:text-xl mb-3 text-gray-900 leading-relaxed
    `} &:last-of-type {
      ${tw`
        mb-0
      `};
    }

    & a {
      ${tw`text-teal-500`} &:hover {
        ${tw`underline text-teal-600`};
      }
    }
  }
`
// ========================

// ========================
// A reusable divider for separating content
const Divider = styled.div`
  ${tw`w-full h-1 bg-gray-100 my-6`};
`

// ========================
// Icons for credits
const CardIcons = styled.div``

const CardIcon = styled.div`
  ${tw`inline-block p-4`};
`
const BckImg = styled.div`
  ${tw`z-0 absolute top-0 left-0 w-screen h-screen overflow-hidden`};
`
const Logo = styled.div`
  ${tw`inline-block w-40`};
`
// const CardIcons = styled.ul`
//   ${tw`
//     flex flex-row content-center items-center
//   `} & li {
//     ${tw`flex items-center mx-6`} &:first-of-type,
//     &:last-of-type {
//       ${tw`mx-0`};
//     }
//   }
// `
// ========================

export default ({ data }) => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{data.site.siteMetadata.title}</title>
    </Helmet>
    <BckImg>
      <Img
        fluid={data.backImage.childImageSharp.fluid}
        style={{ backgroundSize: 'cover', height: '100%' }}
        backgroundColor={`#040e18`}
      />
    </BckImg>
    <Card>
      <CardHeader>Manzai Daiku</CardHeader>
      <Logo>
        <Img fluid={data.logo.childImageSharp.fluid} />
      </Logo>
      <CardCopy>
        <p>
          Estamos renovando nuestra web para ofrecer nuevos productos y
          contenidos
        </p>
        <Divider />
        <p>
          <strong>Contáctanos en</strong>
        </p>
      </CardCopy>
      <CardIcons>
        <CardIcon>
          <SocialIcon
            url="https://www.instagram.com/manzaidaiku/"
            bgColor="red"
          />
        </CardIcon>
        <CardIcon>
          <SocialIcon url="mailto:manzaidaiku@gmail.com" bgColor="red" />
        </CardIcon>
      </CardIcons>
    </Card>
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    logo: file(relativePath: { eq: "Manzai-logo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    backImage: file(relativePath: { eq: "Manzai-bck.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 640) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allImageSharp {
      edges {
        node {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`
